<script>
import 'vue-awesome/icons/thumbs-up'
import Vue from 'vue'
import VueAffix from 'vue-affix'
Vue.use(VueAffix)

export default {
  name: 'encyclopedia',
  data () {
    return {
      human_title: '',
      human_subtitle: ''
    }
  },
  mounted () {
    this.$watch('$route', this.delayedSetTitles, { immediate: true })
  },
  methods: {
    delayedSetTitles () {
      setTimeout(this.setTitles, 200)
    },
    setTitles () {
      this.human_title = $('meta[name="human_title"]').attr('content')
      this.human_subtitle = $('meta[name="human_subtitle"]').attr('content')
      // EE
      $(`#advices_page a[href='${location.pathname.replace(/\/$/, '')}']`).addClass('is-active')
      // EE menu highlighting
    }
  }
}
</script>

<template lang="pug">
#advices_page
  section.hero.is-info(style='margin-top: 3.25rem')
    .hero-body: .container.content
      .title.is-2 {{ human_title }}
      .subtitle.is-4 {{ human_subtitle }}

  section.section: .container: .columns
    .column.is-3
      affix.menu(relative-element-selector='#advices_category_content' :offset="{ top: 100, bottom: 100 }" :enabled='!HM.is_touch')
        .subtitle.is-5 Энциклопедия<br>Экономии
        ul.menu-list(style='width: 210px;')
          li: router-link(to="/ru/ee/auto" title="Как экономить на авто, бензине и обслуживании") <b>Авто</b> и бензин

          li: router-link(to="/ru/ee/byt" title="Как экономить в быту с комфортом") <b>Быт</b>, коммунальные услуги

          li: router-link(to="/ru/ee/deti" title="Дети: экономия на игрушках, вещах, развлечениях и излишествах") <b>Дети</b>: одежда, игрушки и развлечения

          li: router-link(to="/ru/ee/eda" title="Экономия на продуктах питания: вкусные советы") <b>Еда</b>: закупки, готовка, еда вне дома

          li: router-link(to="/ru/ee/zdorovie" title="Экономия на здоровье: умные способы от ХаниМани") <b>Здоровье</b>: врачи, лекарства и больницы

          li: router-link(to="/ru/ee/odejda" title="Экономия на одежде и обуви") <b>Одежда</b> и обувь

          li: router-link(to="/ru/ee/puteshestviya" title="Экономия на путешествиях") <b>Путешествия</b>

          li: router-link(to="/ru/ee/razvlecheniya" title="") <b>Развлечения</b>

          li: router-link(to="/ru/ee/remont" title="Умная экономия на ремонте квартиры и покупке мебели") <b>Ремонт</b>: материалы, мебель, покупки

          li: router-link(to="/ru/ee/uslugi" title="Экономия на интернете, тв и мобильной связи") <b>Услуги</b>: интернет, тв и мобильная связь

          li: router-link(to="/ru/ee/krasota" title="Экономия на уходе за собой") <b>Уход за собой</b>: экономим красиво
    .column
      #advices_category_content.content
        router-view
</template>

<style lang="sass">
.ee_advice
  background-color: #f5f5f5
  &:hover
    background-color: #eaeaea
  .ee_advice_index
    display: block
    margin-top: -10px
    margin-left: -10px
    color: #333
    opacity: 0.4
    font-size: 70%

  a[role='ee_like_advice_link']
    display: inline-block
    margin-left: 10px
    margin-right: 10px
    white-space: nowrap
</style>
